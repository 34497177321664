import { cn } from 'utils'
import IconDown from './select-arrow.svg?react'
import styles from '../../field.module.scss'
import { Select as InputSelect, InputSelectProps } from '../../input-select'
import { Label } from '../../label/label'
import { BaseField, withFormDefaultValues, BaseFieldProps } from '../_base'

export type SelectProps = InputSelectProps &
  BaseFieldProps<string> & {
    placeholder?: string
  }

export class Field extends BaseField<string, SelectProps> {
  static displayName = 'Select'

  getClassName() {
    return cn(super.getClassName(), styles.selectField)
  }

  renderInput(props: InputSelectProps) {
    return (
      <>
        <InputSelect {...props} />
        <IconDown className={styles.append} />
      </>
    )
  }

  render() {
    return (
      <Label
        label={this.label}
        name={this.props.name}
        className={this.getClassName()}
        data-field-type="select"
      >
        {this.renderInput(this.getInputProps())}
        {this.props.children}
        {this.shouldShowValidity() && this.renderValidationMessage()}
      </Label>
    )
  }
}

export const Select = withFormDefaultValues<string, SelectProps>(Field)
