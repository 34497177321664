import { TextInput, TextInputProps } from '../../input-text/text-input'
import { Label } from '../../label/label'
import { BaseField, BaseFieldProps, withFormDefaultValues } from '../_base'

type Props = Omit<TextInputProps, 'type' | 'autoComplete'> & BaseFieldProps<string>
export class Field extends BaseField<string, Props> {
  static displayName = 'SearchField'

  renderInput(props: TextInputProps) {
    return <TextInput {...props} type="search" autoComplete="off" />
  }

  render() {
    return (
      <Label
        label={this.label}
        name={this.props.name}
        className={this.getClassName()}
        data-field-type="search"
      >
        {this.renderInput(this.getInputProps())}
        {this.props.children}
        {this.shouldShowValidity() && this.renderValidationMessage()}
      </Label>
    )
  }
}

export const SearchField = withFormDefaultValues<string, Props>(Field)

SearchField.defaultProps = {}
