import { Footer } from 'components/footer'
import { Header } from 'components/header'
import { Page } from 'components/page'
import { useUser } from 'components/use-user'
import { getUserInitialRoute } from 'components/use-user-progress-steps'
import { SHOW_ERROR_DETAILS } from 'const'
import { routes } from 'const/routes'
import { FC, useEffect } from 'react'
import { useRouteError, isRouteErrorResponse } from 'react-router-dom'
import { Button } from 'ui'
import styles from './error-page.module.scss'

interface Props {}

export const ErrorPage: FC<Props> = () => {
  const [user] = useUser()
  const logoLink = user ? getUserInitialRoute({ user }) : routes.welcome

  const error = useRouteError()
  const isRouteResponse = isRouteErrorResponse(error)
  let message =
    (isRouteResponse && (error.data?.message as string)) ||
    (error as Error)?.message ||
    'Unknown error'

  const isModuleLoadError =
    message.startsWith('Failed to fetch dynamically imported module') ||
    message.startsWith('Loading chunk')

  if (isModuleLoadError) {
    message = 'Failed to load the page. Reloading...'
  }
  useEffect(() => {
    if (!isModuleLoadError) return
    const reloadedAt = sessionStorage.getItem('reloaded_at')
    if (reloadedAt && new Date().getTime() - parseInt(reloadedAt, 10) < 1000) {
      sessionStorage.removeItem('reloaded_at')
    } else {
      sessionStorage.setItem('reloaded_at', new Date().getTime().toString())
      window.location.reload()
    }
  }, [isModuleLoadError])

  return (
    <>
      <Header logoLinkTo={logoLink} />
      <Page className={styles.page}>
        <h1>Oops</h1>

        {isRouteResponse && (
          <>
            <h2>{error.status}</h2>
            <p>{error.statusText}</p>
          </>
        )}
        {SHOW_ERROR_DETAILS && <p className={styles.message}>{message}</p>}
        <footer>
          <Button onClick={() => window?.location?.reload()} theme="primary">
            Refresh
          </Button>
        </footer>
      </Page>
      <Footer />
    </>
  )
}
