import { createRef, CSSProperties, FC, forwardRef, PureComponent, ReactElement } from 'react'
import { cn } from 'utils'
import styles from '../../field.module.scss'
import { useFormProps, useField } from '../../form/context'
import { InputInstance } from '../../input-text/base-input'
import { Label } from '../../label/label'
import { ValidationMessage } from '../../validation-message'

export interface BaseFieldProps<T = any> {
  id?: string
  name: string
  label?: string | ReactElement
  defaultValue?: T | null
  required?: boolean
  readOnly?: boolean
  disabled?: boolean
  hideValidationMessage?: boolean
  className?: string
  style?: CSSProperties
  touched?: boolean
  validationMessage?: string | null
  validationMessages?: Partial<Record<keyof ValidityState, string>>
  ref?: any
  children?: ReactElement | ReactElement[]
  renderValidation?:
    | null
    | ((props: {
        message?: string | null
        className: string
        value: T | null | undefined
      }) => ReactElement | null)
}

export class BaseField<
  T extends any,
  Props extends BaseFieldProps = BaseFieldProps<T>,
  State = any,
> extends PureComponent<Props, State> {
  static defaultProps = {
    hideValidationMessage: false,
  }

  element = createRef<InputInstance<T>>()

  setValue(value: T | null) {
    throw new Error('setValue is not overridden')
    // if (this.element.current) this.element.current.value = String(value ?? '')
  }
  set value(value: T | null) {
    this.setValue(value)
  }

  getValue(): T | null {
    return (this.element.current?.value as T) ?? null
  }
  get value(): T | null {
    return this.getValue()
  }

  protected get label() {
    return this.props.label
  }

  protected getClassName() {
    return cn(styles.field, this.props.className)
  }

  focus() {
    this.element?.current?.focus?.()
  }

  protected shouldShowValidity() {
    const { hideValidationMessage, touched, validationMessage } = this.props
    return !hideValidationMessage && touched && !!validationMessage
  }

  protected getInputClassName() {
    const { touched, validationMessage } = this.props
    return cn(
      styles.input,
      touched && styles.touched,
      this.shouldShowValidity() && validationMessage && styles.invalid,
    )
  }

  protected getInputProps(): Omit<
    Readonly<Props>,
    | 'validationMessage'
    | 'hideValidationMessage'
    | 'children'
    | 'touched'
    | 'label'
    | 'className'
    | 'renderValidation'
  > & { className?: string; ref: any } {
    const {
      // get rid of these props:
      validationMessage,
      label,
      hideValidationMessage,
      className,
      children,
      touched,
      renderValidation,
      // the rest are passed to input
      ...props
    } = this.props
    return {
      ...props,
      ref: this.element,
      className: this.getInputClassName(),
      'aria-invalid': validationMessage ? 'true' : 'false',
    }
  }

  renderInput(props: any): ReactElement {
    throw new Error('override renderField(props)')
  }

  protected renderValidationMessage(props = this.props) {
    const { validationMessage, renderValidation } = props
    const result =
      renderValidation &&
      renderValidation({
        ...props,
        message: validationMessage,
        className: styles.error,
        value: this.getValue(),
      })
    return (
      result ?? (
        <ValidationMessage error className={styles.error}>
          {validationMessage}
        </ValidationMessage>
      )
    )
  }

  render() {
    return (
      <Label
        label={this.label}
        name={this.props.name}
        className={this.getClassName()}
        style={this.props.style}
      >
        {this.renderInput(this.getInputProps())}
        {this.props.children}
        {this.shouldShowValidity() && this.renderValidationMessage()}
      </Label>
    )
  }
}

export const withFormDefaultValues = <T, P extends BaseFieldProps<T>>(
  Component: React.ComponentType<P>,
): FC<Omit<P, 'toched' | 'validationMessage'>> => {
  const WrappedComponent = forwardRef<typeof Component, Omit<P, 'toched' | 'validationMessage'>>(
    (props, ref) => {
      const { name } = props
      const { defaultValues, disabled, readOnly, id: formId } = useFormProps()

      const { touched, validationMessage } = useField<{ [k: string]: string }, string>(name) ?? {}

      // @ts-ignore
      const defaultValue = defaultValues?.hasOwnProperty(name) ? defaultValues[name] : undefined

      return (
        // @ts-ignore
        <Component
          defaultValue={defaultValue}
          readOnly={readOnly}
          disabled={disabled}
          {...props}
          id={props.id ?? [formId, name].join('')}
          touched={touched}
          validationMessage={validationMessage}
          ref={ref}
        />
      )
    },
  )
  //@ts-ignore
  const displayName = Component.displayName || Component.name || 'Field'
  WrappedComponent.displayName = `withFormDefaults(${displayName})`

  //@ts-ignore
  return WrappedComponent
}
