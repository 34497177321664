import { ElementType, FC, LabelHTMLAttributes, ReactNode } from 'react'
import { cn } from 'utils'
import styles from '../field.module.scss'

export interface LabelTextProps {
  className?: string
  as?: ElementType
  children?: ReactNode
}

export const LabelText: FC<LabelTextProps> = ({
  className,
  as: Component = 'span',
  children,
  ...props
}) =>
  children ? (
    <Component {...props} className={cn(styles.labelText, className)}>
      {children}
    </Component>
  ) : null

export interface LabelProps extends Omit<LabelHTMLAttributes<HTMLLabelElement>, 'htmlFor'> {
  name: string
  label?: ReactNode | string
}

export const Label: FC<LabelProps> = ({ name, label, children, className, ...props }) => (
  <label {...props} htmlFor={name} className={cn(styles.label, className)}>
    {children}
    <LabelText>{label}</LabelText>
  </label>
)
