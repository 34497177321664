import { cn } from 'utils'
import styles from './radio.module.scss'
import { InputRadio, InputRadioProps } from '../../input-radio/input-radio'
import { ValidationMessage } from '../../validation-message'
import { BaseField, BaseFieldProps, withFormDefaultValues } from '../_base'

type RadioTheme = 'button' | 'radio'
type Props = Omit<BaseFieldProps<string | number | boolean>, 'children'> &
  InputRadioProps & {
    theme?: RadioTheme
  }

export class Field extends BaseField<string, Props> {
  static displayName = 'RadioField'

  getInputClassName() {
    const { touched, validationMessage, theme } = this.props
    return cn(
      styles.options,
      touched && styles.touched,
      theme && styles[theme],
      this.shouldShowValidity() && validationMessage && styles.invalid,
    )
  }

  getInputProps() {
    const { id, theme, ...props } = super.getInputProps()
    return props
  }

  render() {
    const { name, label, children, disabled, readOnly, className, validationMessage, style } =
      this.props
    const ariaDisabled = disabled || readOnly ? 'true' : undefined
    return (
      <fieldset
        name={name}
        className={cn(styles.radio, className)}
        aria-disabled={ariaDisabled}
        style={style}
      >
        <span className={styles.label}>{label}</span>
        <InputRadio {...this.getInputProps()}>{children}</InputRadio>
        {this.shouldShowValidity() && (
          <ValidationMessage error className={styles.error}>
            {validationMessage}
          </ValidationMessage>
        )}
      </fieldset>
    )
  }
}

export const RadioField = withFormDefaultValues<string | number | boolean, Props>(Field)
