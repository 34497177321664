import { Client, GetConfig, PostConfig, UPLOAD_TIMEOUT, convertToServerData } from 'client'
import { addDays, parseDate } from 'utils/date'
import { OwnerPaymentAccountType } from './owner-payment-account-type'
import { User } from '../user/user'

export interface LeaseChecklist<T extends LeaseChecklist.Action = LeaseChecklist.Action> {
  account_type?: OwnerPaymentAccountType
  action: T
  completed_at?: string
  created_at: string
  expires_at?: string
  item_id: string
  label: string
  lease_id: string
  updated_at?: string
  user: User
  user_id: string
  /** a time when the action was started by the user */
  started_at?: T extends LeaseChecklist.Action.background_check ? string : never
  /** since background check may take time, we will show checkr estimation, (it seems this field is in days not hours) */
  estimated_completion_at?: T extends LeaseChecklist.Action.background_check ? string : never
  /** 
   will show additional short comment(max 20 chars) about the status of the checklist item,
   for now it is used only for background checks. We need to show it to the user.
   */
  remark?: T extends LeaseChecklist.Action.background_check ? LeaseChecklist.Remark : never
}

export namespace LeaseChecklist {
  export type Id = Pick<LeaseChecklist, 'item_id'>

  export const MSG = {
    ACTION: {
      EDIT: 'Edit Checklist Item',
      EDIT_SUBMIT: 'Update',
      EDIT_SUCCESS: 'Checklist item updated.',
      PHOTO_ID_VIEW: 'View Photo ID',
      PHOTO_ID_DOWNLOAD: 'Download photo ID',
    },
    ERR: {
      NOT_FOUND: 'Checklist item not found.',
      NO_PHOTO_ID: 'No photo ID found.',
      EXPIRED: 'The checklist has expired items.',
    },
    LIST_EMPTY: 'No checklist items found.',
  } as const

  export const enum Action {
    first_month_rent = 'first_month_rent',
    deposit = 'deposit',
    photo_id = 'photo_id',
    sign_lease = 'sign_lease',
    guarantor_sign_lease = 'guarantor_sign_lease',
    owner_sign_lease = 'owner_sign_lease',
    background_check = 'background_check',
  }

  export const enum Status {
    completed = 'completed',
    expired = 'expired',
    started = 'started',
    not_started = 'not_started',
  }

  export const enum Remark {
    sent = 'sent',
    started = 'started',
    exception = 'exception',
    completed = 'completed',
  }

  export type WithDocument = LeaseChecklist & {}

  export type Update = {
    completed?: boolean
    estimated_time_seconds?: number | null
    remark?: string | null
    started?: boolean
    expires_at?: string | null
  } & Id

  export const getStatus = (item: LeaseChecklist): Status =>
    isComplete(item)
      ? Status.completed
      : isBackgroundCheck(item) && item.started_at
      ? Status.started
      : item.expires_at && isExpired(item)
      ? Status.expired
      : Status.not_started

  export const hasExpiration = (item: LeaseChecklist) => !!item.expires_at

  export const getExpirationTime = (items?: LeaseChecklist[]) => {
    if (!items?.length) return null
    const min = Math.min(
      ...items
        .filter(hasExpiration)
        .map((item) => parseDate(item.expires_at)?.getTime() ?? Infinity),
    )
    return isFinite(min) ? min : null
  }

  export const isExpired = (item: LeaseChecklist): boolean => {
    if (isComplete(item)) return false
    const time = parseDate(item.expires_at)?.getTime()
    return time ? time < Date.now() : false
  }

  export const getDueDate = (item: LeaseChecklist): Date => addDays(item.created_at, 1)

  export const isActionNeeded = (item: LeaseChecklist): boolean =>
    getStatus(item) === Status.not_started

  export const isComplete = (item: LeaseChecklist): boolean => !!item.completed_at

  export const isCompleteAll = (items: LeaseChecklist[]) => items.every(isComplete)

  export const isPhotoIdType = (item: LeaseChecklist) => item.action === Action.photo_id
  export const hasPhotoId = (item: LeaseChecklist) => isPhotoIdType(item) && isComplete(item)

  export const isRelatedToUser = (item: LeaseChecklist, user: User): boolean =>
    item.user_id ? item.user_id === user.user_id : true

  export const isSignature = (item: LeaseChecklist): boolean => item.action === Action.sign_lease

  export const isSignedByUser = (checklist: LeaseChecklist[], user: User): boolean => {
    const signatureItem = checklist.find((item) => isRelatedToUser(item, user) && isSignature(item))
    return signatureItem ? LeaseChecklist.isComplete(signatureItem) : true
  }
  export const isBackgroundCheck = (item: LeaseChecklist): boolean =>
    item.action === Action.background_check

  export const isPayment = (item: LeaseChecklist): boolean =>
    [Action.first_month_rent, Action.deposit].includes(item.action)

  export const isOwnerSignature = (item: LeaseChecklist): boolean =>
    item.action === Action.owner_sign_lease

  export const getLabel = (item: LeaseChecklist): string => {
    return isBackgroundCheck(item) ? getBackgroundCheckLabel(item) ?? item.label : item.label
  }

  export const RemarkLabel: Record<Remark, string> = {
    [Remark.sent]: 'Background check invite email sent',
    [Remark.started]: 'Background check in progress',
    [Remark.exception]: 'Background check exception',
    [Remark.completed]: 'Background check completed',
  }

  export const getBackgroundCheckLabel = (item: LeaseChecklist): string | undefined => {
    if (!item.started_at) return item.label
    if (isComplete(item)) return RemarkLabel[Remark.completed]
    if (item.remark && item.remark in RemarkLabel) return RemarkLabel[item.remark]
  }

  export const getPercentDone = (items: LeaseChecklist[]): number => {
    if (!items.length) return 0
    const done = items.filter(isComplete).length
    return done / items.length
  }
}

interface ListParams {
  lid: string
  mine?: 'true' | '1'
}

export class LeaseChecklistBackend extends Client {
  /** @see https://api-dev.rello.co/swagger/index.html#/lease/get_lease_checklist_get */
  listByLeaseId = async (params: ListParams, config?: GetConfig): Promise<LeaseChecklist[]> => {
    const { checklist } = await this.get<{ checklist: LeaseChecklist[] }, ListParams>(
      '/lease/checklist/get',
      params,
      config,
    )
    return checklist
  }

  uploadPhoto = (data: FormData) => {
    return this.post('/user/photo/upload', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: UPLOAD_TIMEOUT,
    })
  }

  downloadIdBlob = async (id: string, config?: GetConfig): Promise<Blob | null> => {
    try {
      const blob = await this.get<any, { uid: string }>(
        '/user/photo/download',
        { uid: id },
        {
          ...config,
          responseType: 'blob',
        },
      )
      if (blob.size === 0) throw new Error('Empty blob')
      return blob
    } catch (e) {
      return null
    }
  }

  downloadIdURL = async (
    id: string,
    config?: GetConfig,
  ): Promise<{ url: string; type: string; size: number } | null> => {
    try {
      const blob = await this.downloadIdBlob(id, config)
      if (!blob) return null
      return {
        url: URL.createObjectURL(blob),
        type: blob.type,
        size: blob.size,
      }
    } catch (e) {
      return null
    }
  }

  signURL = async (lid: string, config?: GetConfig): Promise<string> => {
    const { sign_url } = await this.get<any, { lid: string }>('/lease/sign/url', { lid }, config)
    return sign_url
  }

  update = async (data: LeaseChecklist.Update, config?: PostConfig): Promise<void> => {
    await this.post<LeaseChecklist.Update, { status: string }>(
      '/lease/checklist/update',
      convertToServerData(data, {
        date: ['expires_at'],
        number: ['estimated_time_seconds'],
        string: ['remark'],
      }),
      config,
    )
  }
}

export const leaseChecklist = new LeaseChecklistBackend()
