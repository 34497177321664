import { routes } from 'const/routes'
import { FC } from 'react'
import { NavLink } from 'ui'
import styles from './main-nav.module.scss'

interface Props {
  className?: string
}
export const MainNav: FC<Props> = ({ className }) => {
  return (
    <nav role="menu" className={className}>
      <ul className={styles.list}>
        <li>
          <NavLink to={routes.search}>Search</NavLink>
        </li>
        <li>
          <NavLink to={routes.myHome}>My Home</NavLink>
        </li>
      </ul>
    </nav>
  )
}
