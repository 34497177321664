import { FloatingButton } from '@rello/chat/floating-button'
import { Footer } from 'components/footer'
import { Header } from 'components/header'
import { MainNav } from 'components/main-nav'
import { MobileMenu } from 'components/mobile-menu'
import { useUser } from 'components/use-user'
import { getUserInitialRoute } from 'components/use-user-progress-steps'
import { UserMenu } from 'components/user-menu'
import { routes } from 'const/routes'
import { FC, useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { MediaQuery, Outlet } from 'ui'
import { cn } from 'utils'
import { LayoutConfig, LayoutContext } from './context'
import styles from './layout.module.css'

type Props = {
  noHeader?: boolean
  noFooter?: boolean
  stickyHeader?: boolean
}

export const Layout: FC<Props> = ({ noHeader, noFooter, stickyHeader }) => {
  const [user] = useUser()
  const context = useOutletContext()
  const [config, setConfig] = useState<LayoutConfig>({
    header: stickyHeader ? 'sticky' : noHeader ? 'none' : 'static',
  })

  useEffect(() => {
    setConfig({ header: stickyHeader ? 'sticky' : noHeader ? 'none' : 'static' })
  }, [noHeader, noFooter, stickyHeader])

  const to = user ? getUserInitialRoute({ user }) : routes.welcome

  return (
    <div className={styles.container}>
      {config.header !== 'none' && (
        <Header logoLinkTo={to} className={cn(config.header === 'sticky' && styles.sticky)}>
          {user && (
            <>
              <MainNav className={styles.nav} />
              <MediaQuery.NonMobile>
                <UserMenu className={styles.usermenu} />
              </MediaQuery.NonMobile>
              <MediaQuery.Mobile>
                <MobileMenu className={styles.usermenu} />
              </MediaQuery.Mobile>
            </>
          )}
        </Header>
      )}
      <LayoutContext.Provider value={[config, setConfig]}>
        <Outlet context={context} />
      </LayoutContext.Provider>
      {!noFooter && <Footer logoLinkTo={to} />}

      {user && <FloatingButton currentUser={user} />}
    </div>
  )
}
