import { ComponentProps } from 'react'
import { PhoneInput } from '../../input-text/phone-input'
import { BaseField, BaseFieldProps, withFormDefaultValues } from '../_base'

type Props = Omit<
  ComponentProps<typeof PhoneInput> & BaseFieldProps<string>,
  'type' | 'autoComplete'
>
export class Field extends BaseField<string, Props> {
  static displayName = 'PhoneField'

  render() {
    const props = this.getInputProps()
    return (
      <div className={this.props.className}>
        <PhoneInput
          label={this.label}
          containerClass={this.getClassName()}
          {...props}
          {...(this.props.touched && { 'data-touched': true })}
          {...(this.props.validationMessage && { 'data-invalid': true })}
        />
        {this.shouldShowValidity() && this.renderValidationMessage()}
      </div>
    )
  }
}

export const PhoneField = withFormDefaultValues<string, Props>(Field)
